var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Alunos")]),_c('v-spacer'),(_vm.allowCreate && _vm.criarAlunoEnabled && !_vm.loadingMounted)?_c('v-btn',{attrs:{"to":"/main/aluno/create","color":"primary"}},[_vm._v("Criar")]):(!_vm.criarAlunoEnabled)?_c('v-chip',{staticStyle:{"font-weight":"bold","font-family":"'ArticulatCF'"},attrs:{"color":"#3e009f","outlined":""}},[_vm._v("O processo de matricula deverá ser realizado pelo "+_vm._s(_vm.getNomeProspex()))]):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-row',[(_vm.totals.por_contrato_status)?_c('v-col',[_c('GenericListTotalCardComponent',{key:"por_contrato_status",attrs:{"prop-title":"Por status do contrato","prop-items":_vm.totals.por_contrato_status},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.label === 'Vigente')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true)},[_c('span',[_vm._v(" São considerados contratos vigentes os que estão alocados em turmas, independente do status do turma, podendo ser necessário aprovar/reprovar em turmas concluídas. ")])]):_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])],1)]}}],null,true)})],1):_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Por status do contrato "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.carregandoTotals},on:{"click":function($event){return _vm.buscarTotals()}}},[_vm._v(" Carregar ")])],1)],1)],1)],1),_c('v-row',[(_vm.totals.por_aluno_conferido)?_c('v-col',[_c('GenericListTotalCardComponent',{key:"por_aluno_conferido",attrs:{"prop-title":"Por conferência","prop-items":_vm.totals.por_aluno_conferido}})],1):_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Por conferência "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.carregandoTotals},on:{"click":function($event){return _vm.buscarTotals()}}},[_vm._v(" Carregar ")])],1)],1)],1)],1)],1),(_vm.totals.por_contrato_curso)?_c('v-col',[_c('GenericListTotalCardComponent',{key:"por_contrato_curso",attrs:{"prop-title":"Por curso do contrato","prop-items":_vm.totals.por_contrato_curso}})],1):_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Por curso do contrato "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.carregandoTotals},on:{"click":function($event){return _vm.buscarTotals()}}},[_vm._v(" Carregar ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_aluno"},scopedSlots:_vm._u([{key:"item.aluno_nome",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.aluno_nome)),"propObjectId":item.id,"propToolName":"Aluno"}})]}},{key:"item.contratos",fn:function(ref){
var value = ref.value;
return [_c('contrato-chip-component',{attrs:{"propContratoItem":value}})]}},{key:"item.aluno_cpf",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatCpfCnpj")(value))+" ")]}},{key:"item.idade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAge(item.aluno_data_nascimento))+" ")]}},{key:"item.aluno_data_nascimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.aluno_data_nascimento))+" ")]}},{key:"item.conferido_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.conferido_em))+" ")]}},{key:"item.aluno_responsavel_financeiro",fn:function(ref){
var item = ref.item;
return [(item.aluno_responsavel_financeiro)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.aluno_contatos",fn:function(ref){
var item = ref.item;
return _vm._l((item.aluno_contatos),function(contato,idx){return _c('div',{key:idx},[(contato.observation)?_c('span',[_vm._v(_vm._s(contato.observation)+" -")]):_vm._e(),_vm._v(" "+_vm._s(contato.phone)),_c('br')])})}},{key:"item.dados_turma_professor",fn:function(ref){
var value = ref.value;
return _vm._l((value),function(turma_aluno){return _c('div',{key:turma_aluno.id},[(turma_aluno.turma_codigo_referencia)?_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (turma_aluno.turma_codigo_referencia)),"propObjectId":turma_aluno.turma_id,"propToolName":"Turma"}}):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(turma_aluno.professor_nome)+" "),_c('br')],1)})}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }